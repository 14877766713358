<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form method="post" v-on:submit.prevent="handleSubmit(signup)">
                    <h1>Login</h1>
                    <p class="text-muted">Acceda a su cuenta</p>
                    <validation-provider
                        name="Username"
                        :rules="{ required: true }"
                        :customMessages="{ required: 'Es un campo requerido' }"
                        v-slot="validationContext"
                    >

                      <CInput
                          placeholder="Username"
                          autocomplete="username email"
                          type="email"
                          v-model="registro.email"
                      >
                        <template #prepend-content><CIcon name="cil-user"/></template>
                      </CInput>
                      <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                    </validation-provider>

                    <validation-provider
                        name="Password"
                        :rules="{ required: true }"
                        :customMessages="{ required: 'Es un campo requerido' }"
                        v-slot="validationContext"
                    >

                      <CInput
                          placeholder="Password"
                          type="password"
                          autocomplete="curent-password"
                          v-model="registro.password"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-lock-locked"/>
                        </template>
                      </CInput>
                      <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                    </validation-provider>

                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton type="submit" color="primary" class="px-4">Login</CButton>
                      </CCol>
                      <CCol col="6" class="text-right">
                        <CButton color="link" class="px-0">Olvido su contraseña?</CButton>
                        <CButton color="link" class="d-lg-none">Registrese ahora!</CButton>
                      </CCol>
                    </CRow>
                  </form>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <CCard
                color="primary"
                text-color="white"
                class="text-center py-5 d-md-down-none"
                body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Si no estas registrado en el sistema, puedes tomar unos minutos para obtener tu cuenta.</p>
                <CButton
                    color="light"
                    variant="outline"
                    size="lg"
                    @click="register"
                >
                  Registrese ahora!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import httpAuthservice from "@/services/httpAuthservice"
export default {
  name: 'Login',
  data() {
    return {
      registro: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    async signup() {
      try {
        const url = 'login'
        const response = await httpAuthservice.post(url, this.registro)
        const userData = {
          authenticated: true,
          token: response.data.access_token,
          name: '',
          email: '',
          avatar: ''
        }
        await this.$store.dispatch('addUserDataAction', userData)
        this.$vToastify.success("Operación realizada con éxito", "Éxito");
        await this.$router.push({name: 'Home'})
      } catch (error) {
        if (error.response.request.status === 401) {
          this.$vToastify.warning("Usuario no autorizado.")
        } else {
          this.$vToastify.error("Ocurrió un error, comuníquese con el administrador.")
        }
      }
    },
    async register() {
      await this.$router.push({name: 'Register'})
    }
  }
}
</script>