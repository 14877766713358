import axios from 'axios'
import Store from "@/store"

const endPoint = process.env.VUE_APP_AUTH_URL

const apiClient = axios.create({
    baseURL: endPoint,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + Store.getters.getUserData.token
    }
})

export default {

    async get(url) {
        let hasError = false
        let errorMessage = ''
        let result = {}
        try {
            let response = await apiClient.get(url)
            result = response.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                result = {}
                hasError = true
                errorMessage = error.response
            }
        }
        return {
            'hasError': hasError,
            'errorMessage': errorMessage,
            'data': result
        }
    },

    async post(url, data) {
        let hasError = false
        let errorMessage = ''
        let result = {}
        try {
            let response = await apiClient.post(url, data)
            result = response.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                result = {}
                hasError = true
                errorMessage = error.response
            }
        }
        return {
            'hasError': hasError,
            'errorMessage': errorMessage,
            'data': result
        }
    },

    async put(url, data) {
        let hasError = false
        let errorMessage = ''
        let result = {}
        try {
            let response = await apiClient.put(url, data)
            result = response.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                result = {}
                hasError = true
                errorMessage = error.response
            }
        }
        return {
            'hasError': hasError,
            'errorMessage': errorMessage,
            'data': result
        }
    },

    async delete(url) {
        let hasError = false
        let errorMessage = ''
        let result = {}
        try {
            let response = await apiClient.delete(url)
            result = response.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                result = {}
                hasError = true
                errorMessage = error.response
            }
        }
        return {
            'hasError': hasError,
            'errorMessage': errorMessage,
            'data': result
        }
    }

}